import React from "react";
import Check from "../assets/Check.svg";
import Close from "../assets/Close.svg";
import HighPerformer from "../assets/HighPerformer.svg";
import Leader from "../assets/Leader.svg";
import MidLeader from "../assets/Mid-Market_Leader.svg";
import BusinessLeader from "../assets/Small-Business_Leader.svg";
import Supporter from "../assets/BestSupport.svg";
import readMoreIcon from "../assets/g2-read-more-icon.png";

const CompetitorComparison = ({
  feature_table,
  encordScroreClass = "text-xl",
  competitorScoreClass = "text-xl",
  textClass = "text-black",
}) => {
  return (
    <section className="w-full overflow-hidden shadow-header rounded-xl">
      <div className="grid grid-cols-3 pt-12 text-xs text-gray-700 bg-white border-b place-items-center pb-9">
        <div align="center">
          <img
            src={feature_table?.primary?.encord_logo?.url}
            alt={feature_table?.primary?.encord_logo?.alt || "Encord Logo"}
            className="sm:min-w-[127px] min-w-[87px]"
            loading="lazy"
            width={0}
            height={0}
          />
        </div>
        <h4 className="sm:text-3xl text-xl text-[#686868] uppercase text-center">
          Vs
        </h4>
        <div align="center">
          {feature_table?.primary?.competitor_name?.text ? (
            <h4 className="text-xl text-center sm:text-2xl text-gray-1300 -tracking-tighter_3">
              {feature_table?.primary?.competitor_name?.text}
            </h4>
          ) : (
            <img
              src={feature_table?.primary?.competitor_logo?.url}
              alt={feature_table?.primary?.competitor_logo?.alt || "competitor_logo"}
              className="sm:min-w-[127px] min-w-[87px]"
              loading="lazy"
              width={0}
              height={0}
            />
          )}
        </div>
      </div>

      <div className="competitor">
        {feature_table?.items?.map(
          ({
            feature_name,
            competitor,
            encord,
            encord_score,
            competitor_score,
          }) => {
            return (
              <section className="bg-[#FAFAFA] border-b grid grid-cols-3 place-items-center">
                <div>
                  {encord_score?.text ? (
                    <span className={`${encordScroreClass} text-purple-500`}>
                      {encord_score?.text}
                    </span>
                  ) : (
                    <>
                      {encord === "TRUE" ? (
                        <img
                          src={Check}
                          width={0}
                          height={0}
                          className="w-5 h-5 sm:w-7 sm:h-7"
                          alt="competitor"
                        />
                      ) : (
                        <img
                          src={Close}
                          width={0}
                          height={0}
                          className="w-5 h-5 sm:w-6 sm:h-6"
                          alt="competitor"
                        />
                      )}
                    </>
                  )}
                </div>
                <div
                  className={`${textClass} py-4 text-sm font-normal text-center lg:py-9 sm:py-6 lg:text-xl sm:text-lg tracking-tighter_1 whitespace-nowrap`}
                >
                  {feature_name?.text}
                </div>
                <div>
                  {competitor_score?.text ? (
                    <span className={`${competitorScoreClass} text-gray-1200`}>
                      {competitor_score?.text}
                    </span>
                  ) : (
                    <>
                      {competitor === "TRUE" ? (
                        <img
                          src={Check}
                          width={0}
                          height={0}
                          className="w-5 h-5 sm:w-7 sm:h-7"
                          alt="competitor"
                        />
                      ) : (
                        <img
                          src={Close}
                          width={0}
                          height={0}
                          className="w-5 h-5 sm:w-6 sm:h-6"
                          alt="competitor"
                        />
                      )}
                    </>
                  )}
                </div>
              </section>
            );
          }
        )}
      </div>

      <div className="grid grid-cols-1 bg-white sm:grid-cols-3 place-items-center">
        <div className="col-span-2 px-3 py-2 text-purple-500 lg:px-6 md:px-4 lg:py-4 md:py-3 lg:col-span-1">
          <div className="flex justify-center w-72">
            <img
              src={HighPerformer}
              alt="HighPerformer"
              loading="lazy"
              className="sm:w-14 w-11"
              width={0}
              height={0}
            />
            <img
              src={Leader}
              alt="HighPerformer"
              loading="lazy"
              className="sm:w-14 w-11"
              width={0}
              height={0}
            />
            <img
              src={Supporter}
              alt="Supporter"
              loading="lazy"
              className="sm:w-14 w-11"
              width={0}
              height={0}
            />
            <img
              src={MidLeader}
              alt="MidLeader"
              loading="lazy"
              className="sm:w-14 w-11"
              width={0}
              height={0}
            />
            <img
              src={BusinessLeader}
              alt="MidLeader"
              loading="lazy"
              className="sm:w-14 w-11"
              width={0}
              height={0}
            />
          </div>
        </div>
        <div className="md:pl-[20px] pl-3 lg:p-4 md:pr-3 pr-3 md:py-3 py-2 font-medium text-black lg:text-[16px] md:text-[14px] text-[12px] whitespace-nowrap">
          <a
            href="https://www.g2.com/products/encord/reviews"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={readMoreIcon}
              alt="g2-read-more-icon-icon"
              loading="lazy"
              className="h-auto sm:w-44 w-36"
              width={0}
              height={0}
            />
          </a>
        </div>
      </div>
    </section>
  );
};

export default CompetitorComparison;
