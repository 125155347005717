import React from "react";
import WhiteQuote from "../assets/WhiteQuotePurpleShadow.svg";
import "../styles/page/competitor_page.scss";
import G2Logo from "../assets/G2Logo.svg";
import RedRating from "../assets/RedRating.svg";
import CustomLink from "./CustomLink";
import CompetitorTable from "../components/CompetitorTable";

/**
 * @summary This component renders the CustomerRating on the
 * competitor page.
 * @param {Object} reviews object returned by competitor-page;
 * @param {string} cardBg applies the class for card's background;
 * @param {string} buttonText string for naming button used in
 * @returns JSX.Element
 */
const CustomerRating = ({
  reviews,
  cardBg = "reviews_card",
  buttonText,
  feature_table,
  buttonClass="btn_gradient"
}) => {
  return (
    <>
      <div>
        <section>
          <h2 className="mb-4 text-base font-medium tracking-wider text-center uppercase text-purple-1500">
            {reviews?.primary?.section_label?.text}
          </h2>
          <h3 className="sm:text-3.75xl text-[28px] leading-11 sm:tracking-tighter_3 tracking-tighter_1 font-semibold text-center text-gray-600">
            {reviews?.primary?.section_heading?.text}
          </h3>
          {reviews?.primary?.section_description?.text && (
            <p className="text-base sm:text-xl mx-auto leading-7 mt-7 mb-1 w-auto md:w-auto max-w-3xl lg:px-7 sm:-tracking-[0.6px] tracking-tighter_1 font-normal text-center text-[#595959]">
              {reviews?.primary?.section_description?.text}
            </p>
          )}
          {reviews?.primary?.section_link?.url && (
            <section className="flex justify-center pt-6 z-20 relative">
              <CustomLink
                href={reviews?.primary?.section_link?.url}
                target="_blank"
                rel="noopener noreferrer"
                className={`text-white btn h-[50px] ${buttonClass}`}
              >
                {buttonText || "Try Encord today"}
              </CustomLink>
            </section>
          )}
        </section>
        {feature_table && (
          <section className="pt-5 mt-5 sm:pt-7 lg:pt-5">
            <CompetitorTable
              feature_table={feature_table}
              competitorScoreClass="sm:text-xl text-base font-semibold"
              encordScroreClass="sm:text-xl text-base font-semibold"
              textClass="text-[#686868]"
            />
          </section>
        )}

        <section className="px-2 mt-10 lg:mt-16 xl:columns-3 lg:columns-2 columns-1 gap-x-4 xl:px-11 sm:px-10 xl:max-w-full lg:max-w-4xl lg:mx-auto">
          {reviews?.items?.map((item, index) => {
            const { review_heading, review_description, review_author } =
              item || {};
            return (
              <section
                className={`${cardBg} lg:w-96 flex-1 sm:flex-[0.5] lg:flex-1 shadow-lg space-y-4 lg:space-y-6 rounded-2.5xl p-7.5 lg:mb-6 mb-4 border border-white`}
                key={index}
              >
                <section className="flex items-center justify-between">
                  <div className="flex gap-3">
                    <img src={G2Logo} alt="G2Logo"/>
                    <img src={RedRating} alt="RedRating"/>
                  </div>
                  <img src={WhiteQuote} alt="WhiteQuote"/>
                </section>

                <h4 className="text-xl font-semibold line-clamp-4 text-left tracking-tighter_3 text-purple-1000">
                  {review_heading?.text}
                </h4>
                <p className="text-base text-gray-600">
                  {review_description?.text}
                </p>
                <p className="text-base font-semibold text-gray-600">
                  {review_author?.text}
                </p>
              </section>
            );
          })}
        </section>
      </div>
    </>
  );
};

export default CustomerRating;
